export const isSameSenderMargin = (messages, m, i, userId) => {
  if (
    i < messages.length - 1 &&
    messages[i + 1].sender_data.id === m.sender_data.id &&
    messages[i].sender_data.id !== userId
  )
    return 33;
  else if (
    (i < messages.length - 1 &&
      messages[i + 1].sender_data.id !== m.sender_data.id &&
      messages[i].sender_data.id !== userId) ||
    (i === messages.length - 1 && messages[i].sender_data.id !== userId)
  )
    return 0;
  else return "auto";
};

export const isSameSender = (messages, m, i, userId) => {
  return (
    i < messages.length - 1 &&
    (messages[i + 1].sender_data?.id !== m?.sender_data?.id ||
      messages[i + 1].sender_data?.id === undefined) &&
    messages[i].sender_data?.id !== userId
  );
};

export const isLastMessage = (messages, i, userId) => {
  return (
    i === messages.length - 1 &&
    messages[messages.length - 1]?.sender_data?.id !== userId &&
    messages[messages.length - 1]?.sender_data?.id
  );
};

export const isSameUser = (messages, m, i) => {
  return i > 0 && messages[i - 1]?.sender_data.id === m?.sender_data.id;
};

export const getSender = (loggedUser, users) => {
  const otherUsers = users?.filter(
    (user) => user?.id && user?.id !== loggedUser?.id
  );
  return otherUsers?.length > 0
    ? otherUsers?.[0]?.name
    : users?.[0]?.name || users?.[1]?.name;
};

export const getSenderFull = (loggedUser, users) => {
  return users?.[0]?.id === loggedUser?.id ? users?.[1] : users?.[0];
};
