import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useCookies } from "react-cookie";

function ProtectedRoute({
  component: Component,
  accesstype = [],
  ...restOfProps
}) {
  const [cookies] = useCookies(["user"]);
  const isAuthenticated = cookies?.user?.auth === "verified";

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated && accesstype?.includes(cookies?.user?.role) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/user-pages/login-1" />
        )
      }
    />
  );
}

export default ProtectedRoute;
