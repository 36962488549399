import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import { withCookies } from "react-cookie";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/add-caterory", state: "categoryMenuOpen" },
      { path: "/basic-ui", state: "regionMenuOpen" },
      { path: "/basic-ui", state: "regionMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  onClickSignOut = () => {
    const { cookies } = this.props;
    cookies.remove("user");
    window.location.href = "/";
  };

  render() {
    const data = '{"auth":"","email":"","name":"","role":"","id":""}';
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="/dashboard">
            <img
              src={require("../../assets/images/logo/star_logo.png")}
              alt="logo"
            />
            <h2>Starn22</h2>{" "}
          </a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="/dashboard">
            <img
              src={require("../../assets/images/logo/starn22.png")}
              alt="logo"
            />
          </a>
        </div>
        {JSON.parse(this.props?.cookies?.cookies.user || data)?.role ===
          "admin" && (
          <ul className="nav">
            <li
              className={
                this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/dashboard">
                <i className="mdi mdi-view-dashboard menu-icon"></i>
                <span className="menu-title">
                  <Trans>Dashboard</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/sales") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/sales">
                <i className="mdi mdi-view-dashboard menu-icon"></i>
                <span className="menu-title">
                  <Trans>Sales</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/follow-ups")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/follow-ups">
                <i className="mdi mdi-view-dashboard menu-icon"></i>
                <span className="menu-title">
                  <Trans>Follow ups</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/deliveries")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/view-deliveries">
                <i className="mdi mdi-view-deliveries menu-icon"></i>
                <span className="menu-title">
                  <Trans>Deliveries</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/sessions") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/view-sessions">
                <i className="mdi mdi-view-sessions menu-icon"></i>
                <span className="menu-title">
                  <Trans>Session Managment</Trans>
                </span>
              </Link>
            </li>
          
            <li
              className={
                this.isPathActive("/view-pos") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/view-pos">
                <i className="mdi mdi-view-sessions menu-icon"></i>
                <span className="menu-title">
                  <Trans>POS</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/warehouse") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/view-warehouse">
                <i className="mdi mdi-view-sessions menu-icon"></i>
                <span className="menu-title">
                  <Trans>Ware house Managment</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/program") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/view-fidelity-program">
                <i className="mdi mdi-view-sessions menu-icon"></i>
                <span className="menu-title">
                  <Trans>Fidelity program</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/basic-ui") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.regionMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("regionMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                <span className="menu-title">
                  <Trans>Region</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.regionMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-region")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-region"
                    >
                      <Trans>Region</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-new-region")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-new-region"
                    >
                      <Trans>Add new Region</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/basic-ui") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.locationMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("locationMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                <span className="menu-title">
                  <Trans>Location</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.locationMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-location")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-location"
                    >
                      <Trans>Location</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-new-location")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-new-location"
                    >
                      <Trans>Add new Location</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/category") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.categoryMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("categoryMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-cow menu-icon"></i>
                <span className="menu-title">
                  <Trans>Categories</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.categoryMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-category")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-category"
                    >
                      <Trans>View Categories</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-category")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-category"
                    >
                      <Trans>Add New Categories</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/producers") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.producersMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("producersMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-cow menu-icon"></i>
                <span className="menu-title">
                  <Trans>Producers</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.producersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-producers")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-producers"
                    >
                      <Trans>View Producers</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-producers")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-producers"
                    >
                      <Trans>Add New Producers</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/view-emails-history")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.emailsHistoryMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("emailsHistoryMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-cow menu-icon"></i>
                <span className="menu-title">
                  <Trans>Emails Managment</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.emailsHistoryMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-emails-history")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-emails-history"
                    >
                      <Trans>Emails History</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-emails-cc-bcc")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-emails-cc-bcc"
                    >
                      <Trans>Emails CC BCC</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-templates")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-templates"
                    >
                      <Trans>Content Templates</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/view-banners")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.bannersMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("bannersMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-cow menu-icon"></i>
                <span className="menu-title">
                  <Trans>Banners</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.bannersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-banners")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-banners"
                    >
                      <Trans>Banners</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/tables") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.productsMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("productsMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-cow menu-icon"></i>
                <span className="menu-title">
                  <Trans>Products</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.productsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-product")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-product"
                    >
                      <Trans>View Products</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-product")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-product"
                    >
                      <Trans>Add New Products</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/tables") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.productsRegionMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("productsRegionMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-google-nearby menu-icon"></i>
                <span className="menu-title">
                  <Trans>Products in Region</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.productsRegionMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("view-productIn-region")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-productIn-region"
                    >
                      <Trans>View Products</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-productIn-region")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-productIn-region"
                    >
                      <Trans>Add New Products</Trans>
                    </Link>
                  </li>{" "}
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/tables") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.offersMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("offersMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-wallet-giftcard menu-icon"></i>
                <span className="menu-title">
                  <Trans>Offer</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.offersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("view-offer")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-offer"
                    >
                      <Trans>View Offers</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-offer")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-offer"
                    >
                      <Trans>Add New Offer</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/icons") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.routessMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("routessMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-road-variant menu-icon"></i>
                <span className="menu-title">
                  <Trans>Routes</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.routessMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-delivery-day")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/delivery-groups"
                    >
                      View Delivery Groups
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-delivery-day")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-delivery-day"
                    >
                      View Routes
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("add-delivery-day")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-delivery-day"
                    >
                      Add new Delivery Day
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/icons") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.ordersMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("ordersMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-currency-usd menu-icon"></i>
                <span className="menu-title">
                  <Trans>Order</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.ordersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-order")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-order"
                    >
                      View Order
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/icons") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.reportsMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("reportsMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-currency-usd menu-icon"></i>
                <span className="menu-title">
                  <Trans>Reports</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.reportsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-report")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-report"
                    >
                      View Reports
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-report")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-sales-report"
                    >
                      View Sales Dashboard
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/charts") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.customersMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("customersMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-human-greeting menu-icon"></i>
                <span className="menu-title">
                  <Trans>Customers</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.customersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-customers")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-customers"
                    >
                      View Customer
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/customer-sales")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/customer-sales"
                    >
                      Customer Sales
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/notifications")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.notificationsMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("notificationsMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-human-greeting menu-icon"></i>
                <span className="menu-title">
                  <Trans>Notifications</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.notificationsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-notifications")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-notifications"
                    >
                      add Notifications
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-notifications")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-whatsapp"
                    >
                      Send Whatsapp
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-notifications")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-notifications"
                    >
                      View Notifications
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/contact") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.contactMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("contactMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-human-greeting menu-icon"></i>
                <span className="menu-title">
                  <Trans>Contact us</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.contactMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/whatsapp-customers")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/whatsapp-customers"
                    >
                      Whatsapp
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/feedbacks")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/feedbacks"
                    >
                      Feedback
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/contact-customers")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/contact-customers"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/payments") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.payments ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={() => this.toggleMenuState("payments")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-xaml menu-icon"></i>
                <span className="menu-title">
                  <Trans>Payment Methods</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.payments}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-payment")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-payment"
                    >
                      View Payment Method
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-payment")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-payment"
                    >
                      Add Payment Method
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/icons") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.minOrderMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("minOrderMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-xaml menu-icon"></i>
                <span className="menu-title">
                  <Trans>Min Order Value</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.minOrderMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-order-value")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-order-value"
                    >
                      View Order Values
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-order-value")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-order-value"
                    >
                      Add Order Value
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/user-pages")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.taxRateMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("taxRateMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-calculator menu-icon"></i>
                <span className="menu-title">
                  <Trans>Tax Rate</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.taxRateMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-tax-rate")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-tax-rate"
                    >
                      <Trans>View Tax Rate</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-tax-rate")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-tax-rate"
                    >
                      <Trans>Add Tax Rate</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          </ul>
        )}

        {JSON.parse(this.props?.cookies?.cookies.user || data)?.role ===
          "producer" && (
          <ul className="nav">
            <li
              className={
                this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/dashboard">
                <i className="mdi mdi-view-dashboard menu-icon"></i>
                <span className="menu-title">
                  <Trans>Dashboard</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/sales") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/sales">
                <i className="mdi mdi-view-dashboard menu-icon"></i>
                <span className="menu-title">
                  <Trans>Sales</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/deliveries")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/view-deliveries">
                <i className="mdi mdi-view-deliveries menu-icon"></i>
                <span className="menu-title">
                  <Trans>Deliveries</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/sessions") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/view-sessions">
                <i className="mdi mdi-view-sessions menu-icon"></i>
                <span className="menu-title">
                  <Trans>Session Managment</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/basic-ui") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.regionMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("regionMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                <span className="menu-title">
                  <Trans>Region</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.regionMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-region")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-region"
                    >
                      <Trans>Region</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-new-region")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-new-region"
                    >
                      <Trans>Add new Region</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/basic-ui") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.locationMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("locationMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                <span className="menu-title">
                  <Trans>Location</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.locationMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-location")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-location"
                    >
                      <Trans>Location</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-new-location")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-new-location"
                    >
                      <Trans>Add new Location</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/tables") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.productsMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("productsMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-cow menu-icon"></i>
                <span className="menu-title">
                  <Trans>Products</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.productsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-product")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-product"
                    >
                      <Trans>View Products</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-product")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-product"
                    >
                      <Trans>Add New Products</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/tables") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.productsRegionMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("productsRegionMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-google-nearby menu-icon"></i>
                <span className="menu-title">
                  <Trans>Products in Region</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.productsRegionMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("view-productIn-region")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-productIn-region"
                    >
                      <Trans>View Products</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-productIn-region")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-productIn-region"
                    >
                      <Trans>Add New Products</Trans>
                    </Link>
                  </li>{" "}
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/agents") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.agentsMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("agentsMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-cow menu-icon"></i>
                <span className="menu-title">
                  <Trans>Agents</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.agentsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-agents")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-agents"
                    >
                      <Trans>View Agents</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-agents")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-agents"
                    >
                      <Trans>Add New Agents</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/tables") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.offersMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("offersMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-wallet-giftcard menu-icon"></i>
                <span className="menu-title">
                  <Trans>Offer</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.offersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("view-offer")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-offer"
                    >
                      <Trans>View Offers</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-offer")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-offer"
                    >
                      <Trans>Add New Offer</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/icons") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.routessMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("routessMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-road-variant menu-icon"></i>
                <span className="menu-title">
                  <Trans>Routes</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.routessMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-delivery-day")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-delivery-day"
                    >
                      View Routes
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("add-delivery-day")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-delivery-day"
                    >
                      Add new Delivery Day
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/icons") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.ordersMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("ordersMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-currency-usd menu-icon"></i>
                <span className="menu-title">
                  <Trans>Order</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.ordersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-order")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-order"
                    >
                      View Order
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/icons") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.reportsMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("reportsMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-currency-usd menu-icon"></i>
                <span className="menu-title">
                  <Trans>Reports</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.reportsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-report")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-report"
                    >
                      View Report
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/charts") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.customersMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("customersMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-human-greeting menu-icon"></i>
                <span className="menu-title">
                  <Trans>Customers</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.customersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-customers")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-customers"
                    >
                      View Customer
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/customer-sales")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/customer-sales"
                    >
                      Customer Sales
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          </ul>
        )}

        {JSON.parse(this.props?.cookies?.cookies.user || data)?.role ===
          "agent" && (
          <ul className="nav">
            <li
              className={
                this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/dashboard">
                <i className="mdi mdi-view-dashboard menu-icon"></i>
                <span className="menu-title">
                  <Trans>Dashboard</Trans>
                </span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive("/notifications")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.notificationsMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("notificationsMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-human-greeting menu-icon"></i>
                <span className="menu-title">
                  <Trans>Notifications</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.notificationsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-notifications")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-notifications"
                    >
                      add Notifications
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/add-notifications")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/add-whatsapp"
                    >
                      Send Whatsapp
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/view-notifications")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/view-notifications"
                    >
                      View Notifications
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              className={
                this.isPathActive("/contact") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.contactMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("contactMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-human-greeting menu-icon"></i>
                <span className="menu-title">
                  <Trans>Contact us</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.contactMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/whatsapp-customers")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/whatsapp-customers"
                    >
                      Whatsapp
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/feedbacks")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/feedbacks"
                    >
                      Feedback
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/contact-customers")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/contact-customers"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          </ul>
        )}
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withCookies(withRouter(Sidebar));
