import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;
// const baseURL = process.env.REACT_APP_DEV_BASE_URL;
// const baseURL = process.env.REACT_APP_LOCAL_URL;

const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

const toObject = (str) => {
  let foo = str.split("&").reduce(function (obj, str, index) {
    let strParts = str.split("=");
    if (strParts[0] && strParts[1]) {
      obj[strParts[0].replace(/\s+/g, "")] = strParts[1].trim(); // Get rid of extra spaces at beginning of value strings
    }
    return obj;
  }, {});
  return foo;
};

const updateURL = (query) => {
  let obj = toObject(query);
  for (let param in obj) { // eslint-disable-line no-unused-vars
    if (
      obj[param] === undefined || // In case of undefined assignment
      obj[param] === null ||
      obj[param] === ""
    ) {
      delete obj[param];
    }
  }
  return serialize(obj).toString();
};

// Function to clear local storage and cookies, then reload the page
const handleUnauthorized = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("refreshToken");
  document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  window.location.reload();
};

// Create axios instance
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      handleUnauthorized();
    }
    return Promise.reject(error);
  }
);

const api = (url = "", method, data = {}) => {
  if (url.includes("?")) {
    const updatedURL1 = url?.split("?")[0];
    const updatedURL2 = url?.split("?")[1];
    const string = updateURL(updatedURL2);
    url = `${updatedURL1}?${string}`;
  }

  const options = {
    method,
    headers: {
      "content-type": "application/json",
      "x-access-token": `${localStorage.getItem("accessToken")}`,
    },
    data,
    url: `${baseURL}/${url}`,
  };
  return axiosInstance(options);
};

export default api;

const multipartApi = (url = "", method, data = {}) => {
  if (url.includes("?")) {
    const updatedURL1 = url?.split("?")[0];
    const updatedURL2 = url?.split("?")[1];
    const string = updateURL(updatedURL2);
    url = `${updatedURL1}?${string}`;
  }

  const options = {
    method,
    headers: {
      "content-type": "multipart/form-data",
      "x-access-token": `${localStorage.getItem("accessToken")}`,
    },
    data,
    url: `${baseURL}/${url}`,
  };
  return axiosInstance(options);
};

export { multipartApi };

const refreshTokenApi = (url = "", method, data = {}) => {
  const options = {
    method,
    headers: {
      "content-type": "application/json",
      "x-access-token": `${localStorage.getItem("refreshToken")}`,
    },
    data,
    url: `${baseURL}/${url}`,
  };
  return axiosInstance(options);
};

export { refreshTokenApi };
