import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useCookies } from "react-cookie";
import { LogOut } from 'react-feather';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { logout } from '../utils/APIs';
import { getSender } from '../utils/config/ChatLogics';
import { ChatState } from '../utils/Context/ChatProvider';

const Navbar = () => {
  const [, , removeCookie] = useCookies();
  const {
    notification,
    setNotification,
    setSelectedChat,
    user
  } = ChatState();

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  const signOut = async () => {
    await logout()
    setTimeout(() => {
      removeCookie('user', { path: '/' });
      toast.success('session expired', { autoClose: 4000 })
    }, 100);
  }

  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt => evt.preventDefault()}><img src={require("../../assets/images/logo/starn22.png")} alt="logo" className='max-width-15' /></a>
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <i className="mdi mdi-menu"></i>
        </button>
        <form className="ml-auto search-form d-none d-md-block" action="#">
          <div className="form-group">
            <input type="search" className="form-control" placeholder="Search Here" />
          </div>
        </form>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item  nav-profile border-0 pl-4">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                <i className="mdi mdi-bell-outline"></i>
                <span className="count bg-success">{notification.length}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list">
                {!notification.length && <Dropdown.Item className="dropdown-item py-3 d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                  <p className="mb-0 font-weight-medium float-left"><Trans>{!notification.length ? "No New Messages" : `${notification.length} new notifications`}</Trans> </p>
                </Dropdown.Item>}
                <div className="dropdown-divider"></div>
                {
                  notification?.map((notif) => {
                    return (
                      <Dropdown.Item className="dropdown-item py-3 d-flex align-items-center" href="!#" onClick={(evt) => {
                        evt.preventDefault()
                        setSelectedChat(notif.chat_data);
                        setNotification(notification.filter((n) => n !== notif));
                      }}>
                        <p className="mb-0 font-weight-medium float-left">
                          <Trans>
                            {notif.chat_data.isGroupChat === 1
                              ? `New Message in ${notif.chat_data.chatName}`
                              : `New Message from ${getSender(user, notif.users)}`}
                          </Trans> </p>
                      </Dropdown.Item>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </li>

          <li className="nav-item  nav-profile border-0">
            <LogOut onClick={signOut} className="pointer" />
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  )
}

export default Navbar