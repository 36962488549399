import api, { multipartApi } from './axios';

// login
export const adminLogin = (data) => api('admin/login', 'POST', data)
export const producerLogin = (data) => api('producer/login', 'POST', data)
export const agentLogin = (data) => api('sales_agent/login', 'POST', data)
export const login = (data) => api('login', 'POST', data)
export const logout = (data) => api('admin/logout', 'POST', data)
export const resetUserPassword = (data) => api('reset-password', 'POST', data)

// Recommendations
export const notificationRecommendation = (data) => api(`recommendation/notifications`, 'POST', data);
export const wspRecommendation = (data) => api(`recommendation/whatsapp`, 'POST', data);
export const callsRecommendation = (data) => api(`recommendation/calls`, 'POST', data);
export const inPersonRecommendation = (data) => api(`recommendation/inperson`, 'POST', data);

// Feedbacks
export const getFeedbacks = () => api(`get_feedbacks`, 'GET');
export const deleteFeedbacks = (id) => api(`get_feedbacks_by_id/${id}`, 'DELETE');
export const updateFeedbacks = (id, data) => api(`update_feedbacks/${id}`, 'PATCH', data);

// Permissions
export const fetchPermissions = (data) => api(`get_permissions`, 'POST', data);
export const savePermissions = (data) => api(`change_permissions`, 'POST', data);

// Sales FollowUps
export const getSalesFollowUps = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_sales_followups?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createSalesFollowUps = (data) => api(`add_sales_followups`, 'POST', data);
export const createWSPFollowUps = (data) => api(`add_whatsapp_followups`, 'POST', data);
export const updateSalesFollowUps = (data, id) => api(`update_sales_followups/${id}`, 'PATCH', data);
export const deleteSalesFollowUps = (id) => api(`delete_sales_followups/${id}`, 'DELETE');

// Sales Users
export const getSalesUsers = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_sales_users?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createSalesUsers = (data) => api(`add_sales_users`, 'POST', data);
export const updateSalesUsers = (data, id) => api(`update_sales_users/${id}`, 'PATCH', data);
export const deleteSalesUsers = (id) => api(`delete_sales_users/${id}`, 'DELETE');
export const getSalesUsersByAgentId = (id, size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_sales_users_by_agent_id/${id}?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');

// Sales Visit
export const getSalesVisits = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_sales_visits?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const getSalesVisitsByUserId = (id, size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_sales_visits_by_user/${id}?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const getSalesVisitDetailById = (id) => api(`get_sales_visits_by_id/${id}`, 'GET');

// Region apis
export const fetchRegion = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_region?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const addRegion = (data) => api(`add_region`, 'POST', data);
export const updateRegion = (data, id) => api(`update_region/${id}`, 'POST', data);
export const getRegionById = (id) => api(`get_region/${id}`, 'GET');
export const getOfferByRegionId = (id) => api(`get_offer_discount_by_offer_id/${id}`, 'GET');
export const deleteRegionById = (id) => api(`delete_region/${id}`, 'DELETE');

// Emails History
export const getEmailsHistory = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_emails_history?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createEmailsHistory = (data) => api(`add_emails_history`, 'POST', data);
export const updateEmailsHistory = (data, id) => api(`update_emails_history/${id}`, 'PATCH', data);
export const deleteEmailsHistory = (id) => api(`delete_emails_history/${id}`, 'DELETE');

// Emails History
export const getEmailsCcBcc = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_emails_cc_bcc?size=${size}&page=${page}&email=${name}&orderBy=${sortBy}`, 'GET');
export const createEmailsCcBcc = (data) => api(`add_emails_cc_bcc`, 'POST', data);
export const updateEmailsCcBcc = (data, id) => api(`update_emails_cc_bcc/${id}`, 'PATCH', data);
export const deleteEmailsCcBcc = (id) => api(`delete_emails_cc_bcc/${id}`, 'DELETE');

// Templates
export const getTemplates = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_content_templates?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createTemplates = (data) => api(`add_content_templates`, 'POST', data);
export const updateTemplates = (data, id) => api(`update_content_templates/${id}`, 'PATCH', data);
export const deleteTemplates = (id) => api(`delete_content_templates/${id}`, 'DELETE');

// Banners
export const getBanners = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_banners?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createBanners = (data) => api(`add_banners`, 'POST', data);
export const updateBanners = (data, id) => multipartApi(`update_banners/${id}`, 'PATCH', data);
export const updateBannersDetail = (data, id) => api(`update_banners_detail/${id}`, 'PATCH', data);
export const deleteBanners = (id) => api(`delete_banners/${id}`, 'DELETE');

// RouteDeliveryLimit
export const getRouteDeliveryLimit = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_route_delivery_limits?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createRouteDeliveryLimit = (data) => api(`add_route_delivery_limits`, 'POST', data);
export const updateRouteDeliveryLimit = (data) => api(`update_route_delivery_limits_by_name`, 'PATCH', data);
export const deleteRouteDeliveryLimit = (id) => api(`delete_route_delivery_limits_by_slug/${id}`, 'DELETE');

// Store Category
export const storeCategories = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_store_category?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createStoreCategory = (data) => api(`add_store_category`, 'POST', data);
export const updateStoreCategory = (data, id) => api(`update_store_category/${id}`, 'PATCH', data);
export const deleteStoreCategory = (id) => api(`delete_store_category/${id}`, 'DELETE');

// deliveryClaims
export const deliveryClaims = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_delivery_claims?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createDeliveryClaims = (data) => api(`add_delivery_claims`, 'POST', data);
export const updateDeliveryClaims = (data, id) => api(`update_delivery_claims/${id}`, 'PATCH', data);
export const deleteDeliveryClaims = (id) => api(`delete_delivery_claims/${id}`, 'DELETE');

// deliveryissue
export const deliveryIssues = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_delivery_issues?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createDelivryIssues = (data) => api(`add_delivery_issues`, 'POST', data);
export const updateDelivryIssues = (data, id) => api(`update_delivery_issues/${id}`, 'PATCH', data);
export const deleteDeliveryIssues = (id) => api(`delete_delivery_issues/${id}`, 'DELETE');

// Delivery Image Category
export const getDeliveryImageCategories = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_delivery_image_categories?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createDeliveryImageCategories = (data) => api(`add_delivery_image_categories`, 'POST', data);
export const updateDeliveryImageCategories = (data, id) => api(`update_delivery_image_categories/${id}`, 'PATCH', data);
export const deleteDeliveryImageCategories = (id) => api(`delete_delivery_image_categories/${id}`, 'DELETE');

// Delivery Image Category
export const getPickingImageCategories = (size = 10, page = 0, name = '', sortBy = 'row_number:asc') => api(`get_delivery_picking_image_categories?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createPickingImageCategories = (data) => api(`add_delivery_picking_image_categories`, 'POST', data);
export const updatePickingImageCategories = (data, id) => api(`update_delivery_picking_image_categories/${id}`, 'PATCH', data);
export const getPickingselectedImageCategories = (data) => api(`get_delivery_picking_selected_image_categories?size=${1000}&delivery_date=${data}`,'GET');

// Return Product Category
export const getReturnProductCategory = (size = 1000, page = 0, name = '', sortBy = 'row_number:asc') => api(`return_reasons?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createReturnProductCategory = (data) => api(`return_reasons`, 'POST', data);
export const updateReturnProductCategory = (data, id) => api(`return_reasons/${id}`, 'PUT', data);
export const deleteReturnProductCategory = (data, id) => api(`return_reasons/${id}`, 'DELETE', data);

// Sales Image Category
export const getImageCategories = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_image_categories?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createImageCategories = (data) => api(`add_image_categories`, 'POST', data);
export const updateImageCategories = (data, id) => api(`update_image_categories/${id}`, 'PATCH', data);
export const deleteImageCategories = (data,id) => api(`delete_image_categories/${id}`, 'DELETE',data);

// Process Dropped
export const getProcessDropped = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_process_dropped?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createProcessDropped = (data) => api(`add_process_dropped`, 'POST', data);
export const updateProcessDropped = (data, id) => api(`update_process_dropped/${id}`, 'PATCH', data);
export const deleteProcessDropped = (data,id) => api(`delete_process_dropped/${id}`, 'DELETE',data);

// Sales Achievements
export const getSalesAchievements = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_sales_achievements?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createSalesAchievements = (data) => api(`add_sales_achievements`, 'POST', data);
export const updateSalesAchievements = (data, id) => api(`update_sales_achievements/${id}`, 'PATCH', data);
export const deleteSalesAchievements = (data,id) => api(`delete_sales_achievements/${id}`, 'DELETE',data);

// Sales Arguments
export const getSalesArguments = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_sales_arguments?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createSalesArguments = (data) => api(`add_sales_arguments`, 'POST', data);
export const updateSalesArguments = (data, id) => api(`update_sales_arguments/${id}`, 'PATCH', data);
export const deleteSalesArguments = (data,id) => api(`delete_sales_arguments/${id}`, 'DELETE',data);

// Sales Products
export const getSalesProducts = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_sales_product?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createSalesProducts = (data) => api(`add_sales_product`, 'POST', data);
export const updateSalesProducts = (data, id) => api(`update_sales_product/${id}`, 'PATCH', data);
export const deleteSalesProducts = (id) => api(`delete_sales_product/${id}`, 'DELETE');

// Sales Agents
export const getSalesAgents = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_sales_agent?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createSalesAgents = (data) => api(`add_sales_agent`, 'POST', data);
export const updateSalesAgents = (data, id) => api(`update_sales_agent/${id}`, 'PATCH', data);
export const deleteSalesAgents = (id) => api(`delete_sales_agent/${id}`, 'DELETE');


// Deliveries Agents
export const getDeliveryAgents  = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_delivery_agent?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const createDeliveryAgents  = (data) => api(`add_delivery_agent`, 'POST', data);
export const updateDeliveryAgents  = (data, id) => api(`update_delivery_agent/${id}`, 'PATCH', data);
export const deleteDeliveryAgents  = (id) => api(`delete_delivery_agent/${id}`, 'DELETE');
export const getAgentDetailByOrder = (id) => api(`get_delivery_agent_by_order/${id}`, 'GET');

// Location apis
export const fetchLocation = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_location?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const addLocation = (data) => api(`add_location`, 'POST', data);
export const updateLocation = (data, id) => api(`update_location/${id}`, 'POST', data);
export const getLocationById = (id) => api(`get_location_by_id/${id}`, 'GET');
export const getLocationByRegion = (id, size, page, name) => api(`get_location_by_region/${id}?name=${name}&size=${size}&page=${page}`, 'GET');
export const deleteLocationById = (id) => api(`delete_location/${id}`, 'DELETE');
export const getLocationByCity = (id) => api(`get_location_by_city/${id}`, 'GET');

// Category apis
export const fetchCategory = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_category?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const addCategory = (data) => api(`add_category`, 'POST', data);
export const updateCategory = (data, id) => api(`update_category/${id}`, 'PATCH', data);
export const getCategoryById = (id) => api(`get_category_by_id/${id}`, 'GET');
export const deleteCategoryById = (id) => api(`delete_category/${id}`, 'DELETE');

// Payment methods apis
export const fetchPayment = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_payment?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const addPayment = (data) => api(`add_payment`, 'POST', data);
export const updatePayment = (data, id) => api(`update_payment/${id}`, 'PATCH', data);
export const getPaymentById = (id) => api(`get_payment_by_id/${id}`, 'GET');
export const deletePaymentById = (id) => api(`delete_payment/${id}`, 'DELETE');

// Producer Payment methods apis
export const fetchProducerPayment = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_producer_payment?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const addProducerPayment = (data) => api(`add_producer_payment`, 'POST', data);
export const updateProducerPayment = (data, id) => api(`update_producer_payment/${id}`, 'PATCH', data);
export const getProducerPaymentById = (id) => api(`get_producer_payment_by_id/${id}`, 'GET');
export const getProducerPaymentByProducer = (id) => api(`get_producer_payment_by_producer/${id}`, 'GET');
export const deleteProducerPaymentByProducer = (producerId, paymentId) => api(`delete_producer_payment_by_producer/${producerId}/${paymentId}`, 'DELETE');
export const deleteProducerPaymentById = (id) => api(`delete_producer_payment/${id}`, 'DELETE');

// Product main apis
export const fetchProductMain = (size = 10, page = 0, name = '', sortBy = 'id:desc', producerId = 0,regionId=0) => api(`get_product_main?size=${size}&page=${page}&name=${name}&orderBy=createdAt:asc&producer_id=${producerId}&region_id=${regionId}&&orderBy=${sortBy}`, 'GET');
export const fetchProductMainByRegion = (size = 10, page = 0, name = '', id = '') => api(`get_product_main_by_region?size=${size}&page=${page}&name=${name}&region_id=${id}&orderBy=createdAt:asc`, 'GET');
export const addProductMain = (data) => api(`add_product_main`, 'POST', data);
export const updateProductMain = (data, id) => api(`update_product_main/${id}`, 'PATCH', data);
export const getProductMainById = (id) => api(`get_product_main_by_id/${id}`, 'GET');
export const deleteProductMainById = (id) => api(`delete_product_main/${id}`, 'DELETE');

// Producers apis
export const fetchProducer = (size = 10, page = 0, name = '', sortBy = 'order:desc') => api(`get_producer?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const updateProducerOrder = (data) => api(`update_producer_order`, 'POST', data);
export const addProducer = (data) => api(`add_producer`, 'POST', data);
export const updateProducer = (data, id) => api(`update_producer/${id}`, 'POST', data);
export const getProducerById = (id) => api(`get_producer/${id}`, 'GET');
export const deleteProducerById = (id) => api(`delete_producer/${id}`, 'DELETE');

// Agents apis
export const fetchAgent = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_agent?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const addAgent = (data) => api(`add_agent`, 'POST', data);
export const updateAgent = (data, id) => api(`update_agent/${id}`, 'PATCH', data);
export const getAgentById = (id) => api(`get_agent_by_id/${id}`, 'GET');
export const deleteAgentById = (id) => api(`delete_agent/${id}`, 'DELETE');

// Users of agent
export const get_users_of_agent = (id , size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_users_of_agent/${id}?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const deleteUserOfAgent = (id) => api(`delete_user_of_agent/${id}`, 'DELETE');
export const addUserOfAgent = (data) => api(`assign_users`, 'POST', data);

// POS Product apis
export const fetchPosProductMaster = (size = 10, page = 0, name = '', sortBy = 'id:desc', producerId = 0) => api(`get_pos_products?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}&producer_id=${producerId}`, 'GET');
export const addPosProductMaster = (data) => api(`add_pos_products`, 'POST', data);
export const updatePosProductMaster = (data, id) => api(`update_pos_products/${id}`, 'PATCH', data);
export const getPosProductMasterById = (id) => api(`get_pos_products_by_id/${id}`, 'GET');
export const deletePosProductMasterById = (id) => api(`delete_pos_products/${id}`, 'DELETE');

// Product master apis
export const fetchProductMaster = (size = 10, page = 0, name = '', sortBy = 'id:desc', producerId = 0) => api(`get_product_master?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}&producer_id=${producerId}`, 'GET');
export const addProductMaster = (data) => api(`add_product_master`, 'POST', data);
export const updateProductMaster = (data, id) => api(`update_product_master/${id}`, 'PATCH', data);
export const getProductMasterById = (id) => api(`get_product_master_by_id/${id}`, 'GET');
export const deleteProductMasterById = (id) => api(`delete_product_master/${id}`, 'DELETE');

// Offer master apis
export const fetchOffer = (size = 10, page = 0, name = '', sortBy = 'id:desc', producerId = 0) => api(`get_offer_description?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}&producer_id=${producerId}`, 'GET');
export const addOffer = (data) => api(`add_offer_description`, 'POST', data);
export const updateOffer = (data, id) => api(`update_offer_description/${id}`, 'PATCH', data);
export const getOfferById = (id) => api(`get_offer_description_by_id/${id}`, 'GET');
export const deleteOfferById = (id) => api(`delete_offer_description/${id}`, 'DELETE');

// Offer discount apis
export const fetchOfferDiscount = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_offer_discount?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const addOfferDiscount = (data) => api(`add_offer_discount`, 'POST', data);
export const updateOfferDiscount = (data, id) => api(`update_offer_discount/${id}`, 'PATCH', data);
export const getOfferDiscountById = (id) => api(`get_offer_discount_by_id/${id}`, 'GET');
export const deleteOfferDiscountById = (id) => api(`delete_offer_discount/${id}`, 'DELETE');

// Routes apis
export const fetchRoutes = (size = 10, page = 0, name = '', sortBy = 'id:desc', region = "") => api(`get_route?size=${size}&page=${page}&route_master=${name}&orderBy=${sortBy}&region=${region}`, 'GET');
export const fetchRoutesGroupedByLocation = (name = '') => api(`group_by_location?name=${name}`, 'GET');
export const addRoutes = (data) => api(`add_route`, 'POST', data);
export const updateRoutes = (data, id) => api(`update_route/${id}`, 'PATCH', data);
export const getRoutesById = (id) => api(`get_route_by_id/${id}`, 'GET');
export const getRoutesByLocationIdAndDay = (id, day) => api(`get_route_by_location_id/${id}/${day}`, 'GET');
export const getRoutesByLocationId = (id, day) => api(`get_route_by_location_id/${id}`, 'GET');
export const deleteRoutesById = (id) => api(`delete_route/${id}`, 'DELETE');

// minimum order value apis
export const fetchLatestOrderValue = (size = 10, page = 0, name = '') => api(`get_order_value`, 'GET');
export const fetchOrderValue = (size = 10, page = 0, name = '') => api(`get_order_value?size=${size}&page=${page}&name=${name}&orderBy=order_value:asc`, 'GET');
export const addOrderValue = (data) => api(`add_order_value`, 'POST', data);
export const updateOrderValue = (data, id) => api(`update_order_value/${id}`, 'PATCH', data);
export const getOrderValueById = (id) => api(`get_order_value_by_id/${id}`, 'GET');
export const deleteOrderValueById = (id) => api(`delete_order_value/${id}`, 'DELETE');

// tax rate value apis
export const fetchTaxRate = (size = 10, page = 0, name = '') => api(`get_latest_tax_rate?size=${size}&page=${page}&name=${name}&orderBy=tax_rate:asc`, 'GET');
export const addTaxRate = (data) => api(`add_tax_rate`, 'POST', data);
export const updateTaxRate = (data, id) => api(`update_tax_rate/${id}`, 'PATCH', data);
export const getTaxRateById = (id) => api(`get_tax_rate_by_id/${id}`, 'GET');
export const deleteTaxRateById = (id) => api(`delete_tax_rate/${id}`, 'DELETE');

// order apis
export const fetchOrders = (size = 10, page = 0, name = '', sortBy = 'id:desc', producerId = 0, deliveryDate = "",showUpfrontPayments=false) => api(`get_orders_master?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}&producer_id=${producerId}&deliveryDate=${deliveryDate}&includeLastOrderStatus=${showUpfrontPayments}`, 'GET');
export const fetchTodayOrders = (size = 10, page = 0, name = '', sortBy = 'id:desc', producerId = 0) => api(`get_orders_master?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}&producer_id=${producerId}&today=yes`, 'GET');
export const addOrders = (data) => api(`add_orders_master`, 'POST', data);
export const updateOrders = (data, id) => api(`update_orders_master/${id}`, 'PATCH', data);
export const getOrdersById = (id) => api(`get_orders_master_by_id/${id}`, 'GET');
export const countTotalOrders = (producerId) => api(`get_total_orders_count?producer_id=${producerId}`, 'GET');
export const countPendingOrders = (producerId) => api(`get_pending_orders_count?producer_id=${producerId}`, 'GET');
export const countDeliveredOrders = (producerId) => api(`get_completed_orders_count?producer_id=${producerId}`, 'GET');
export const deleteOrdersById = (id) => api(`delete_orders_master/${id}`, 'DELETE');

// order detail apis
export const getOrderDetailById = (id) => api(`get_order_details_by_id/${id}`, 'GET');
export const getOrderDetailByOrderId = (id) => api(`get_order_details_by_order_id/${id}?size=${10000}`, 'GET');
export const getOrderFeedbackByOrderId = (id) => api(`get_delivery_feedbacks_by_order/${id}`, 'GET');
export const addOrderOfAgent = (data) => api(`assign_orders`, 'POST', data);
export const uploadDeliveryImage = (data) => multipartApi(`upload_delivery_image`,'POST',data)
export const addOcrInvoicingDetails = (data) => api(`add_multiple_ocr_invoicing_details`,'POST',data)
export const updateOrderQuantity = (data) => api(`edit_order_quantity`,'POST',data)
export const addProductInOrder = (data) => api(`add_products_to_order`,'POST',data)
export const changeProductInOrder = (data) => api(`change_order_product`,'POST',data)

// order status`
export const getOrderStatusTypes = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_order_status_types?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');

// users apis
export const fetchUsers = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`fetch_users?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const fetchAdmins = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`fetch_admins?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const getUsersById = (id) => api(`fetch_user_by_id/${id}`, 'GET');
export const updateUsersById = (data,id) => api(`update_user/${id}`, 'POST', data);
export const forgetPassword = (data,id) => api(`forget-password`, 'POST', data);
export const createUser = (data) => api(`add_user`, 'POST', data);
export const createAdmin = (data) => api(`add_admin`, 'POST', data);
export const deleteUsersById = (id) => api(`delete_user_by_id/${id}`, 'DELETE');
export const countUsers = (producerId) => api(`get_users_count?producer_id=${producerId}`, 'GET');
export const searchUsers = (name = '') => api(`fetch_all_users?name=${name}&size=5`, 'GET');
export const linkSalesUser = (id,data) => api(`link_sales_user/${id}`, 'POST', data);
export const assignSalesAgentToUser = (data, id) => api(`assign_sales_agent_user/${id}`, 'POST', data)

// whatsapp message
export const getWspChats = () => api(`whatsapp/chats`, 'POST')
export const getWspChatMsg = (data) => api(`whatsapp/chat`, 'POST', data)
export const addNewWspMessages = (data) => api(`whatsapp/send`, 'POST', data)
export const sendWhatsappByContent = (data) => api(`whatsapp/send_whatsapp`, 'POST', data)
export const markWspMsgAsRead = (data) => api(`whatsapp/mark_as_read`, 'POST', data)

// chat message
export const fetchChats = () => api(`chat`, 'GET')
export const createChat = (data) => api(`chat`, 'POST', data)
export const fetchGroupMessages = (id) => api(`message/${id}`, 'GET')
export const addNewMessages = (data) => api(`message`, 'POST', data)
export const sendFileToUser = (data) => multipartApi(`message/upload`, 'POST', data)

// notifications apis
export const addSalesNotification = (data) => api(`send_sales_notifications`, 'POST', data)
export const addAllNotification = (data) => api(`sendToAll`, 'POST', data)
export const fetchNotifications = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`fetch_notifications?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const fetchNotificationsUsers = (id, size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`fetch_notifications_details/${id}?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const getNotificationById = (id) => api(`fetch_notification_by_id/${id}`, 'GET');
export const deletNotificationById = (id) => api(`delete_notification_by_id/${id}`, 'DELETE');

// notifications templates
export const fetchNotificationTemplates = (size = 10, page = 0, name = '') => api(`get_templates?size=${size}&page=${page}&name=${name}&orderBy=id:asc`, 'GET');
export const addNotificationTemplates = (data) => api(`add_templates`, 'POST', data);
export const updateNotificationTemplates = (data, id) => api(`update_templates/${id}`, 'PATCH', data);
export const getNotificationTemplatesById = (id) => api(`get_templates/${id}`, 'GET');
export const deleteNotificationTemplatesById = (id) => api(`delete_templates/${id}`, 'DELETE');

// report generation
export const generateReport = (data) => api(`generate_report`, 'POST', data)
export const generateInvoicing = (data) => api(`generate_invoicing`, 'POST', data)
export const generateDeliveryReport = (data) => api(`generate_delivery_report`, 'POST', data)
export const generateSummaryReport = (data) => api(`generate_summary`, 'POST', data)
export const generateOrderDetails = (data) => api(`generate_orders_detail`, 'POST', data)
export const generateSalesChart = (data) => api(`generate_sales_chart`, 'POST', data)
export const generateSalesChartAgent = (data) => api(`generate_sales_chart_agent`, 'POST', data)
export const generateUserPerRouteChart = (data) => api(`generate_users_per_route`, 'POST', data)
export const weeklyReport = (data) => api(`weekly_sales`, 'POST', data)

// billing invoice
export const addInvoice = (data) => api(`add_invoice`, 'POST', data)
export const deleteInvoice = (id) => api(`delete_invoice_details/${id}`, 'DELETE')

//WareHouse
export const addWareHouse= (data) => api(`add_warehouse`, 'POST', data)
export const updateWareHouse = (data,id) => api(`update_warehouse/${id}`, 'PATCH',data)
export const getWareHouse = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`get_warehouse?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const getWareHouseById = (id) => api(`get_warehouse/${id}`, 'GET');

// delivery address
export const addAddress = (data) => api(`add_address`, 'POST', data)
export const deleteAddress = (id) => api(`delete_delivery_address/${id}`, 'DELETE')

// sales report
export const getSalesReport = (
  salesNumber,
  averageSales,
  startDate,
  endDate,
  data
) =>
  api(
    `sales-report?salesNumber=${salesNumber}&averageSales=${averageSales}&startDate=${startDate}&endDate=${endDate}`,
    "POST",
    data
  );

export const generateCustomerOrderDate = (data) => api(`generate_customer_order_date`, 'POST', data)
export const generateCustomerOrderSalesDate = (data) => api(`generate_customer_order_date_sales`, 'POST', data)
export const generateCustomerDeliverOrderSalesDate = (data) => api(`generate_customer_deliver_order_date_sales`, 'POST', data)
export const generateProducerSalesReport = (data) => api(`generate_sales_report_producer`, 'POST', data)
export const generateDailyDeliveryReport = (data) => api(`generate_daily_delivery_report`, 'POST', data)
export const generateDeliveryOrderHistory = (data,size,queryParam) =>api(`get_delivered_orders_history?size=${size}&deliveryDate=${data}&${queryParam}`,"GET")
export const generateCustomerDeliverOrderDate = (data) => api(`generate_customer_deliver_order_date`, 'POST', data)

  // sales agents report
export const getSalesAgentsReport = (
  startDate,
  endDate,
  route_id = ""
) =>
  api(
    `get_sales_agent_report?startDate=${startDate}&endDate=${endDate}&route_id=${route_id}`,
    "GET"
  );

// delivery Dashboard
export const getDeliveryDashboard = (data) => api(`get_my_routes_dashboard`,'POST',data)

// cash received history
export const geCashReceivedHistory = (data) =>api(`get_cash_deposit_history`,"POST",data)

// warehouse
export const uploadWarehouseInvoicing = (data) =>api(`upload_invoice_image`,"POST",data)
export const addInvoicingRecords = (data) =>api(`add_warehouse_invoicing`,"POST",data)
export const updateInvoicingRecords = (id, data) =>api(`update_warehouse_invoicing/${id}`,"PATCH",data)
export const addWarehouseCreditNotesRecords = (data) =>api(`add_warehouse_credit_notes`,"POST",data)
export const getUploadedWarehouseInvoicing = (size = 10, page = 0, name ="" ,sortBy = 'id:desc') =>api(`get_uploaded_warehouse_invoicing?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`,"GET")
export const getUploadedWarehouseCreditNotes = (size = 10, page = 0, name ="" ,sortBy = 'id:desc') =>api(`get_uploaded_warehouse_credit_notes?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`,"GET")
export const generateWareHouseStockMovement = (data) =>api(`generate_stock_movement_report`,"POST",data)
export const generateCurrentStockReport =(data) => api(`generate_current_stock_report`,"POST",data)
export const generateSecurityStockReport =(data) => api(`generate_security_stock_report`,"POST",data)
export const fetchWareHouseMovement = (data) => api(`fetch_warehouse_movements?size=${15000}`,"POST",data)

// assign daily customer
export const addSalesAgentAssignment = (data) =>api(`add_sales_agent_assignment`,"POST",data)
export const getSalesAgentAssignment = (size = 10, page = 0, selectedAssignDate= '',selectedSalesAgent = 0 ,sortBy = 'id:desc') =>api(`get_sales_agent_assignments?size=${size}&page=${page}&assignment_date=${selectedAssignDate}&name=${selectedSalesAgent}&orderBy=${sortBy}`,"GET")

//compare product
export const getCompareProduct = (id)=> api(`get_product_compare_list?product_master_id=${id}`,"GET")
export const updateCompareProduct = (id,data)=>api(`update_product_compare/${id}`,"POST",data)

//performa invoice
export const addProformaInvoice = (data)=>api(`add_proforma_invoicing`,"POST",data)
export const getProformaInvoice = (size = 10, page = 0, name ="" ,sortBy = 'id:desc')=>api(`get_uploaded_proforma_invoicing?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`,"GET")
export const getProformaInvoiceDetail =(data)=>api(`get_proforma_invoicing_by_invoice/${data}`,"GET")
export const getWareHouseInvoicingDetail =(data, size = 10000,) =>api(`get_warehouse_invoicing_by_invoice/${data}?size=${size}`,"GET")
export const editProformaInvoiceDetail =(data) =>api(`update_proforma_invoicing`,"POST",data)
export const getNextPONumbar=()=>api(`get_next_po_number`,"GET")


// place order
export const getCategoryProductList = (data) =>api(`Category_Productlist`, "POST", data);
export const placeOrder = (data) => api(`admin_place_order`,"POST",data);

export const getProductsWithOffers = (region_id) => 
  api(`get_products_with_offers?region_id=${region_id}`, 'GET');
export const getMinTaxRate =()=>api(`Min_tax_rate`,"GET")


// warehouse-manager
export const createWareHouseManager =(data)=>api(`warehouse_manager`,"POST",data)
export const getWareHouseManager = (size = 10, page = 0, name = '', sortBy = 'id:desc') => api(`warehouse_manager?size=${size}&page=${page}&name=${name}&orderBy=${sortBy}`, 'GET');
export const updateWareHouseManager =(data,id)=>api(`warehouse_manager/${id}`,"PATCH",data)
export const deleteWareHouseManager =(id)=>api(`warehouse_manager/${id}`,"DELETE")


// fidelity-user
export const getWeekDeliveriesAndFidelityStatus =(id)=>api(`weekly_deliveries/${id}`,"GET")

// margins
export const getMarginData = (startDate, endDate, sortBy = '') => 
  api(`get_margin_report?start_date=${startDate}&end_date=${endDate}&orderBy=${sortBy}`, 'GET');

// internal invoice
export const submitInternalInvoiceDetails =(data)=>multipartApi(`internal_invoice_details`,"POST", data)
export const getInternalInvoiceDetails =(id)=>api(`internal_invoice_details/${id}`,"GET")